import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Header from './Components/Header';
import Top from './Components/Top';
import Private from './Components/Private';
import Login from './Auth/Login';
import Verify from './Auth/Verify';
import Logout from './Auth/Logout';

const App = () => (
  <div>
    <Header />
    <main>
      <Routes>
        <Route path="/" element={<Top/>} exact />
        <Route path="/login" element={<Login/>} exact />
        <Route path="/private" element={<Private/>} exact />
        <Route path="/logout" element={<Logout/>} exact />
        <Route path="/verify" element={<Verify/>} exact />
        }}/>
      </Routes>
    </main>
  </div>
)

export default App