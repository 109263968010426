import React from 'react';
import { Navigate } from 'react-router-dom';
import Lock from './Lock';
import isVerify from './isVerify';

const Verify = (props) => (

  isVerify()
)

export default Verify;