import React from 'react';
import { Navigate } from 'react-router-dom';
import Lock from './Lock';
import isAuthenticated from './isAuthenticated';

const Login = (props) => (

  isAuthenticated() ? (
    <Navigate to={{
      pathname: '/private',
      state: { from: props.location }
    }} />
  ) : (

    <Lock location={props.location} />
  )
)

export default Login;