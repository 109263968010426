import auth0 from 'auth0-js';

export default function isVerify() {

  var auth0Client = new auth0.WebAuth({
  domain: 'prod.oauthplayground.geoffit.co.uk',
  redirectUri: 'https://lock-embedded.vercel.app',
  clientID: 'mbMSqGBmpboU2Q4xQRQB1XC0oudRkIAV',
  responseType: 'token id_token'
  });

  auth0Client.crossOriginVerification();
}